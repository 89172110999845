import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Collapse, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import classnames from 'classnames';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import blogdetailsbannerImg from '../../assets/images/blogdetailsbanner.png';
import fbIcon from '../../assets/images/fbIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.svg';
import linkedIcon from '../../assets/images/linkedIcon.svg';


const BlogDetails = (props) => {
    return (
        <Fragment>
            <section className="BlgDetaBnr">
                <div className="container">
                    <div className="row align-items-center">                        
                        <div className="col-lg-12">
                            <div className="BlgDetaBnrCnt">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <g opacity="0.8" clip-path="url(#clip0_177_1508)">
                                    <path d="M16.25 19.5L9.75 13L16.25 6.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_177_1508">
                                    <rect width="26" height="26" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 26 26)"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                <span>Popular Articles</span>
                            </div>
                            <h3>5 ways to become core<br/> Java  developer</h3>
                        </div>                        
                    </div>
                </div>
            </section>
            <section className="BlgDetaExp">
               <div className="container">
                    <div className="BlgDetaExpHd">
                        <div className="BlgDetaExpHdL">
                            <h5>Mellissa Suzzano</h5>
                            <h6>Senior Trainee, Osiz Labs</h6>
                        </div>
                        <div className="BlgDetaExpHdR">
                            <div className="d-flex flex-wrap align-items-center">
                            <div className="socialIcon">
                                <img src={fbIcon} />
                            </div>
                            <div className="socialIcon">
                                <img src={twitterIcon} />
                            </div>
                            <div className="socialIcon">
                                <img src={linkedIcon} />
                            </div>                          
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur. Ultrices habitant netus netus at libero sed vivamus faucibus at. Arcu nunc nibh convallis sollicitudin eget urna urna. Lobortis urna malesuada amet dui risus nibh pellentesque porta volutpat. Magna tempus egestas nisi dictumst.</p>
                    <p>Nulla tincidunt nibh odio lorem blandit tortor ridiculus at. Commodo nisl etiam lorem ac ipsum. Habitant maecenas lobortis pretium id rhoncus. Adipiscing euismod sit et a eu at maecenas mauris magna. Nibh elementum tortor lorem porta integer sapien suspendisse in. Ut ut facilisis pulvinar ac. Montes et elit metus enim vitae nec. Pharetra sagittis neque lobortis ut. Lacus tellus sed molestie vel id ac. Adipiscing velit pulvinar dolor lorem volutpat. Tortor ut dictumst leo nec diam viverra. Rutrum sed odio vitae urna enim porttitor ultricies purus in.</p>
                    <p>Lorem ipsum dolor sit amet consectetur. Ultrices habitant netus netus at libero sed vivamus faucibus at. Arcu nunc nibh convallis sollicitudin eget urna urna. Lobortis urna malesuada amet dui risus nibh pellentesque porta volutpat. Magna tempus egestas nisi dictumst.</p>
                    <p>Nulla tincidunt nibh odio lorem blandit tortor ridiculus at. Commodo nisl etiam lorem ac ipsum. Habitant maecenas lobortis pretium id rhoncus. Adipiscing euismod sit et a eu at maecenas mauris magna. Nibh elementum tortor lorem porta integer sapien suspendisse in. Ut ut facilisis pulvinar ac. Montes et elit metus enim vitae nec. Pharetra sagittis neque lobortis ut. Lacus tellus sed molestie vel id ac. Adipiscing velit pulvinar dolor lorem volutpat. Tortor ut dictumst leo nec diam viverra. Rutrum sed odio vitae urna enim porttitor ultricies purus in.</p>
                    <img src={blogdetailsbannerImg} className="w-100"/>
                    <p>Lorem ipsum dolor sit amet consectetur. Ultrices habitant netus netus at libero sed vivamus faucibus at. Arcu nunc nibh convallis sollicitudin eget urna urna. Lobortis urna malesuada amet dui risus nibh pellentesque porta volutpat. Magna tempus egestas nisi dictumst.</p>
                    <p>Nulla tincidunt nibh odio lorem blandit tortor ridiculus at. Commodo nisl etiam lorem ac ipsum. Habitant maecenas lobortis pretium id rhoncus. Adipiscing euismod sit et a eu at maecenas mauris magna. Nibh elementum tortor lorem porta integer sapien suspendisse in. Ut ut facilisis pulvinar ac. Montes et elit metus enim vitae nec. Pharetra sagittis neque lobortis ut. Lacus tellus sed molestie vel id ac. Adipiscing velit pulvinar dolor lorem volutpat. Tortor ut dictumst leo nec diam viverra. Rutrum sed odio vitae urna enim porttitor ultricies purus in.</p>
                    <p>Lorem ipsum dolor sit amet consectetur. Ultrices habitant netus netus at libero sed vivamus faucibus at. Arcu nunc nibh convallis sollicitudin eget urna urna. Lobortis urna malesuada amet dui risus nibh pellentesque porta volutpat. Magna tempus egestas nisi dictumst.</p>
                    <p>Nulla tincidunt nibh odio lorem blandit tortor ridiculus at. Commodo nisl etiam lorem ac ipsum. Habitant maecenas lobortis pretium id rhoncus. Adipiscing euismod sit et a eu at maecenas mauris magna. Nibh elementum tortor lorem porta integer sapien suspendisse in. Ut ut facilisis pulvinar ac. Montes et elit metus enim vitae nec. Pharetra sagittis neque lobortis ut. Lacus tellus sed molestie vel id ac. Adipiscing velit pulvinar dolor lorem volutpat. Tortor ut dictumst leo nec diam viverra. Rutrum sed odio vitae urna enim porttitor ultricies purus in.</p>
               </div>
            </section>
        </Fragment>
    );
   
}

export default BlogDetails;