import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Enquiry = () => {
    const dispatch = useDispatch()
    const [getCourse, setCourse] = useState('')
    const [loader, setLoader] = useState(false)
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Name is required')
            .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
            .max(30)
            .min(3, "Must be more than 2 characters"),

        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .trim(),
        batchtype: Yup.string()
            .required('Batch is required')
            .trim(),
        course: Yup.string()
            .required('Course is required')
            .trim(),

        phonenumber: Yup.string()
            .required('Phone number is required')
            .matches(/^(?!(\d)\1{9})[6,7,8,9]\d{9}$/, 'only accept Indian phone numbers')
            //    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid Number")
            // .lessThan(10, "phone number should not be more than 10 digits")
            // .matches(/^[6-9]\d{9}$/gi, "Phone number is not valid")
            .trim(),

    });


    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    useEffect(() => {
        dispatch(userAction.GetCourseList({}, (resp) => {
            if (resp.data.error == false) {
                setCourse(resp.data.resp)
            }
        }))
    }, [])


    async function onSubmit(data) {
        setLoader(true)
        dispatch(userAction.AddContact({
            name: data.name,
            email: data.email,
            phonenumber: data.phonenumber,
            coursename: data.course,
            batch: data.batchtype
        }, (resp) => {
            if (resp.data.error == false) {
                Swal.fire({
                    title: "Submission successful ! Thank you for contacting us, We'll get back to you shortly.",
                    icon: "success",
                    showClass: {
                        popup: 'animate__animated animate__zoomIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutDown'
                    },
                    confirmButtonColor: "#9B6FF8",
                    confirmButtonText: "Okay",
                }).then(
                    (result) => {
                        if (result.isConfirmed) {
                            reset()
                            setLoader(false)
                        }
                    }
                );
                reset()
                setLoader(false)

            }
            else {
                toast.error(resp.data.message)
                reset()
                setLoader(false)
            }
        }))


    }




    const joiningBatchList = [
        {
            id: "1",
            batch: "Immediately",
        },
        {
            id: "2",
            batch: "In 2 weeks",
        },
        {
            id: "3",
            batch: "In a month",
        },
    ];

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <input type="text"
                            autoComplete="off"
                            maxLength={50}
                            placeholder=""
                            {...register('name')}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            id="inputname" />
                        <label for="inputname">Name <span >*</span></label>
                        <div className="invalid-feedback errorColor">{errors.name?.message}</div>

                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <input type="email"
                            autoComplete="off"
                            maxLength={50}
                            {...register('email')}
                            placeholder=""
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="inputemail" />
                        <label for="inputemail">Email <span >*</span></label>
                        <div className="invalid-feedback errorColor"  >{errors.email?.message}</div>

                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <input type="text"
                            autoComplete="off"
                            maxLength={10}
                            placeholder=""
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            {...register('phonenumber')}
                            className={`form-control ${errors.phonenumber ? 'is-invalid' : ''}`}
                            id="inputphone" />
                        <label for="inputphone">Phone Number <span >*</span></label>
                        <div className="invalid-feedback errorColor">{errors.phonenumber?.message}</div>

                    </div>

                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <select name="course" {...register("course")}
                            className={`${errors.course ? "is-invalid" : ""}`}>
                            <option selected="true" value="">{"Select Course"} <span>*</span> </option>
                            {getCourse && getCourse.map((d) => <option value={d.coursetitle}>{d.coursetitle}</option>)}
                        </select>
                        <div className="invalid-feedback errorColor">{errors.course?.message}</div>

                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <select name="batchtype" {...register("batchtype")}
                            className={`${errors.batchtype ? "is-invalid" : ""}`}>
                            <option selected="true" value="">{"Select the Batch"} <span>*</span> </option>
                            <option style={{ fontWeight: "bold" }} disabled value="">{"Planning to start Training"} </option>
                            {joiningBatchList && joiningBatchList.map((d) => <option value={d.batch}>{d.batch}</option>)}
                        </select>
                        <div className="invalid-feedback errorColor">{errors.batchtype?.message}</div>
                    </div>
                </div>
                {loader ?
                    <button className="btn commonBtn">
                        <Spinner animation="border" size="sm" className="mr-2" />Loading...</button> :
                    <button type="submit" className="btn commonBtn">Submit</button>
                }

            </form>
        </div>
    )
}

export default Enquiry
