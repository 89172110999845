import { CourseService } from "../services";
import { CourseConstant } from "../constants";
import { baseAction } from "./baseAction";
const { request, failure, success } = baseAction;

export const CourseAction = {
  CourseList,
  SingleCourseGet,
};

function CourseList(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await CourseService.CourseList(data);
      callback(resp);
      dispatch(success(CourseConstant.COURSELIST, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}

function SingleCourseGet(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await CourseService.SingleCourseGet(data);
      callback(resp);
      dispatch(success(CourseConstant.SINGLECOURSEGET, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}