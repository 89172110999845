import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerImg from '../../assets/images/bannerImg.png';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Aos from "aos";

const HomeSlider = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [sliderText, setSliderText] = useState('')
    const [getCourse, setCourse] = useState('')
    const [skeletonLoader, setSkeletonLoader] = useState(false);

    useEffect(() => {
        setSkeletonLoader(true);
        dispatch(userAction.GetCourseList({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setCourse(resp.data.resp)
                    setSkeletonLoader(false);
                }
                else{
                    // display a api reponse error
                    setSkeletonLoader(false);
                }
            }
            catch(e){
                // display a catch error
                setSkeletonLoader(false);
            }
        }))
        dispatch(userAction.ListCms({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setSliderText(resp.data.resp)
                }
                else{
                    // display a api reponse error
                }
            }
            catch(e){
                // display a catch error
            }
        }))
        Aos.init({ once: true });
    }, []);

    let slider1Text = sliderText && sliderText.find(d => d._id === "6572b83fef7b79095ac75de4")
    let slider2Text = sliderText && sliderText.find(d => d._id === "6572be85ef7b79095ac760cf")
    let slider3Text = sliderText && sliderText.find(d => d._id === "6572bf20ef7b79095ac76113")

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const settings1 = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };

    const SliderSkeleton = () => {
        return  <div className="col-xl-7 d-flex justify-content-start align-items-end pr-lg-0 order-2 order-xl-1">
            <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                <div className="bannerTxtCnt">
                    <h5 className="subHeading"><span>Expert</span> in Training</h5>
                    <h3 className="heading"><Skeleton width="150%" height="50px"  /></h3>
                    <div className="col-lg-6 px-0">
                    <h3 className="heading"><Skeleton width="280px" height="30px"  /></h3>
                    </div>
                    <a className="btn commonBtn" ><Skeleton width="100px" height="16px" /></a>
                    <div className="countsTxtCnt d-flex align-items-center flex-wrap">
                        <div className="countsCnt d-flex flex-column">
                        <Skeleton width="50px" height="50px"  />
                        </div>
                        <div className="countsCnt d-flex flex-column">
                        <Skeleton width="50px" height="50px"  />
                                </div>
                                <div className="countsCnt d-flex flex-column">
                                <Skeleton width="50px" height="50px"  />
                                </div>
                    </div>
                </div>
                </SkeletonTheme>
            </div>
    }


    return (
        
            <div className="container-fluid">
            <Slider {...settings}>
                <div>
                    <div className="container">
                        <div className="row">
                        { skeletonLoader ? ( <SliderSkeleton />
                       ) : (
                        
                            <div className="col-xl-7 d-flex justify-content-start align-items-end pr-lg-0 order-2 order-xl-1">
                                { slider1Text &&
                                <div className="bannerTxtCnt">
                                    <h5 className="subHeading"><span>Expert</span> in Training</h5>
                                    <h3 className="heading">{slider1Text && slider1Text.title || 'No Data Found' }</h3>
                                    {/* <h3 className="heading">Select your path to <span>succeed...!</span></h3> */}
                                    <div className="col-lg-6 px-0">
                                        <p dangerouslySetInnerHTML={{ __html: slider1Text && slider1Text.content || 'No Data Found' }}></p>
                                    </div>
                                    <a className="btn commonBtn" onClick={()=>navigate('/contact')}>Enquire Now</a>
                                    <div className="countsTxtCnt d-flex align-items-center flex-wrap">
                                        <div className="countsCnt d-flex flex-column">
                                            <h3>{getCourse && getCourse.length || 0}<span>+</span></h3>
                                            <p>Courses</p>
                                        </div>
                                        <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Alumni</p>
                                                </div>
                                                {/* <div className="countsCnt d-flex flex-column">
                                                    <h3>1500<span>+</span></h3>
                                                    <p>Hiring Partners</p>
                                                </div> */}
                                                <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Expert Trainers</p>
                                                </div>
                                    </div>
                                </div> }
                            </div> )}
                            
                            <div className="col-xl-5 d-flex align-items-center justify-content-center pl-lg-0 order-1 order-xl-2" data-aos="fade-left" data-aos-duration="1000">
                                <img src={bannerImg} className="bannerImg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                        { skeletonLoader ? ( <SliderSkeleton />
                       ) : (
                            <div className="col-xl-7 d-flex justify-content-start align-items-end pr-lg-0 order-2 order-xl-1">
                                { slider2Text &&
                                <div className="bannerTxtCnt">
                                    <h5 className="subHeading"><span>Expert</span> in Training</h5>
                                    <h3 className="heading">{slider2Text && slider2Text.title || 'No Data Found' }</h3>

                                    <div className="col-lg-6 px-0">
                                    <p dangerouslySetInnerHTML={{ __html: slider2Text && slider2Text.content || 'No Data Found' }}></p>

                                    </div>
                                    <a className="btn commonBtn" onClick={()=>navigate('/contact')}>Enquire Now</a>
                                    <div className="countsTxtCnt d-flex align-items-center flex-wrap">
                                        <div className="countsCnt d-flex flex-column">
                                            <h3>{getCourse && getCourse.length || 0}<span>+</span></h3>
                                            <p>Courses</p>
                                        </div>
                                        <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Alumni</p>
                                                </div>
                                                {/* <div className="countsCnt d-flex flex-column">
                                                    <h3>1500<span>+</span></h3>
                                                    <p>Hiring Partners</p>
                                                </div> */}
                                                <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Expert Trainers</p>
                                                </div>
                                    </div>
                                </div>}
                            </div> )}
                            <div className="col-xl-5 d-flex align-items-center justify-content-center pl-lg-0 order-1 order-xl-2" data-aos="fade-left" data-aos-duration="1000">
                                <img src={bannerImg} className="bannerImg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                        { skeletonLoader ? ( <SliderSkeleton />
                       ) : (
                            <div className="col-xl-7 d-flex justify-content-start align-items-end pr-lg-0 order-2 order-xl-1">
                                { slider3Text &&
                                <div className="bannerTxtCnt">
                                    <h5 className="subHeading"><span>Expert</span> in Training</h5>
                                    <h3 className="heading">{slider3Text && slider3Text.title || 'No Data Found' }</h3>

                                    <div className="col-lg-6 px-0">
                                    <p dangerouslySetInnerHTML={{ __html: slider3Text && slider3Text.content || 'No Data Found' }}></p>
                                    </div>
                                    <a className="btn commonBtn" onClick={()=>navigate('/contact')}>Enquire Now</a>
                                    <div className="countsTxtCnt d-flex align-items-center flex-wrap">
                                        <div className="countsCnt d-flex flex-column">
                                            <h3>{getCourse && getCourse.length || 0}<span>+</span></h3>
                                            <p>Courses</p>
                                        </div>
                                        <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Alumni</p>
                                                </div>
                                                {/* <div className="countsCnt d-flex flex-column">
                                                    <h3>1500<span>+</span></h3>
                                                    <p>Hiring Partners</p>
                                                </div> */}
                                                <div className="countsCnt d-flex flex-column">
                                                    <h3>100<span>+</span></h3>
                                                    <p>Expert Trainers</p>
                                                </div>
                                    </div>
                                </div>}
                            </div>)}
                            <div className="col-xl-5 d-flex align-items-center justify-content-center pl-lg-0 order-1 order-xl-2" data-aos="fade-left" data-aos-duration="1000">
                                <img src={bannerImg} className="bannerImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
            </div>
        
    )
}

export default HomeSlider
