import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Collapse, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import classnames from 'classnames';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import faqarr from '../../assets/images/faqarr.png';


const FAQ = (props) => {

    return (
        <Fragment>
            <section className="FAQBnr">
                <div className="container">
                    <h3>Frequently Asked Questions</h3>
                    <p>Quick answers to questions you may have...!</p>
                </div>
            </section>
            <section className="FAQDtsDv">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="FAQDtsDvCnt">
                                <h4>General</h4>
                                <h5>Getting Started (5)</h5>
                                <Accordion>
                                    <AccordionItem header="Why Should I Learn the Java Course in Chennai AT FITA Academy?">
                                        The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="Are Java Trainers well-equipped at FITA Academy in Chennai?">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="What are the objectives of Java Training in Chennai at FITA Academy?">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="Top reasons to consider a career in Java.">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="What payment methods do you accept?">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="FAQDtsDvCnt">
                                <h4>Students</h4>
                                <h5>FAQ (10)</h5>
                                <Accordion>
                                    <AccordionItem header="What are the different Java certifications?">
                                        The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="What is the best way to learn advanced Java">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="What are the objectives of Java Training in Chennai at FITA Academy?">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies.
                                    </AccordionItem>

                                    <AccordionItem header="Top reasons to consider a career in Java.">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>

                                    <AccordionItem header="What payment methods do you accept?">
                                    The main credit cards Visa and Mastercard are accepted. You can also pay by bank transfer, AUREA, META, USDT cryptocurrencies
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="FAQDtsDvCnt">
                                <h4>Announcement</h4>
                                <h5>News (2)</h5>
                                <Accordion>
                                    <AccordionItem header="Are Java Trainers well-equipped at Osiz Labs Academy in Chennai?">
                                    Top reasons to consider a career in Java.
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
   
}

export default FAQ;