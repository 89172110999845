import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import {  TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';
import Madurai from "../../assets/maps/Madurai"
import mapPointer from '../../assets/images/mapPointer.png';
import Chennai from "../../assets/maps/Chennai";
import Trichy from "../../assets/maps/Trichy";
import Coimbatore from "../../assets/maps/Coimbatore";
import Tirunelveli from "../../assets/maps/Tirunelveli";
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";

const Maps = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');
    // const [Maduraitext, setMaduraiText] = useState('')
    // const [ChennaiText, setChannaiText] = useState('')
    // const [CoimbatoreText, setCoimbatoreText] = useState('')
    // const [TrichyText, setTrichytext] = useState('')
    // const [TirunulveliText, setTrinelveliText] = useState('')
    const [mapBanners, setMapBanners] = useState('')

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        dispatch(userAction.ListCms({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setMapBanners(resp.data.resp)
                }
                else{
                    // display a api reponse error
                }
            }
            catch(e){
                // display a catch error
            }
        }))
        // getCMSListDatas();
    }, []);

    const Maduraitext = mapBanners && mapBanners.find(d => d._id === "6572a6d2ef7b79095ac7530a")
    const ChennaiText = mapBanners && mapBanners.find(d => d._id === "6572a6e2ef7b79095ac75311")
    const CoimbatoreText = mapBanners && mapBanners.find(d => d._id === "6572a702ef7b79095ac7531f")
    const TrichyText = mapBanners && mapBanners.find(d => d._id === "6572a6f4ef7b79095ac75318")
    const TirunulveliText = mapBanners && mapBanners.find(d => d._id === "6572a70fef7b79095ac75326")

    // const getCMSListDatas = () => {
    //     dispatch(userAction.GetSingleCms({
    //         id: "6572a6d2ef7b79095ac7530a",
    //     }, (resp) => {
    //         setMaduraiText(resp.data.resp)
    //     }))
    //     dispatch(userAction.GetSingleCms({
    //         id: "6572a6e2ef7b79095ac75311",
    //     }, (resp) => {
    //         setChannaiText(resp.data.resp)
    //     }))
    //     dispatch(userAction.GetSingleCms({
    //         id: "6572a6f4ef7b79095ac75318",
    //     }, (resp) => {
    //         setTrichytext(resp.data.resp)
    //     }))
    //     dispatch(userAction.GetSingleCms({
    //         id: "6572a702ef7b79095ac7531f",
    //     }, (resp) => {
    //         setCoimbatoreText(resp.data.resp)
    //     }))
    //     dispatch(userAction.GetSingleCms({
    //         id: "6572a70fef7b79095ac75326",
    //     }, (resp) => {
    //         setTrinelveliText(resp.data.resp)
    //     }))
    // };
    return (
        <div>
            <Nav tabs className="justify-content-center">
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                        Madurai
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} >
                        Chennai
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} >
                        Trichy
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} >
                        Coimbatore
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }} >
                        Tirunelveli
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="mduCnt">
                    <div className="w-100 d-flex justify-content-center">
                        <Madurai />
                        <div className="mapInfoCnt">
                            <div className="d-flex align-items-center mb-2">
                                <img src={mapPointer} className="mr-2" />
                                <h3 className="heading">{Maduraitext && Maduraitext.title || 'No Data Found' }</h3>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: Maduraitext && Maduraitext.content || 'No Data Found' }}></p>

                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="2" className="mduCnt">
                    <div className="w-100 d-flex justify-content-center">
                        <Chennai />
                        <div className="mapInfoCnt">
                            <div className="d-flex align-items-center mb-2">
                                <img src={mapPointer} className="mr-2" />
                                <h3 className="heading">{ChennaiText && ChennaiText.title || 'No Data Found' }</h3>

                            </div>
                            <p dangerouslySetInnerHTML={{ __html: ChennaiText && ChennaiText.content || 'No Data Found' }}></p>

                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="3" className="mduCnt">
                    <div className="w-100 d-flex justify-content-center">
                        <Trichy />
                        <div className="mapInfoCnt">
                            <div className="d-flex align-items-center mb-2">
                                <img src={mapPointer} className="mr-2" />
                                <h3 className="heading">{TrichyText && TrichyText.title || 'No Data Found' }</h3>

                            </div>
                            <p dangerouslySetInnerHTML={{ __html: TrichyText && TrichyText.content || 'No Data Found' }}></p>

                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="4" className="mduCnt">
                    <div className="w-100 d-flex justify-content-center">
                        <Coimbatore />
                        <div className="mapInfoCnt">
                            <div className="d-flex align-items-center mb-2">
                                <img src={mapPointer} className="mr-2" />
                                <h3 className="heading">{CoimbatoreText && CoimbatoreText.title || 'No Data Found' }</h3>

                            </div>
                            <p dangerouslySetInnerHTML={{ __html: CoimbatoreText && CoimbatoreText.content || 'No Data Found' }}></p>

                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="5" className="mduCnt">
                    <div className="w-100 d-flex justify-content-center">
                        <Tirunelveli />
                        <div className="mapInfoCnt">
                            <div className="d-flex align-items-center mb-2">
                                <img src={mapPointer} className="mr-2" />
                                <h3 className="heading">{TirunulveliText && TirunulveliText.title || 'No Data Found' }</h3>

                            </div>
                            <p dangerouslySetInnerHTML={{ __html: TirunulveliText && TirunulveliText.content || 'No Data Found' }}></p>

                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default Maps
