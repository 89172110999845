import { Link, useNavigate } from "react-router-dom";

import footerImg from '../../assets/images/footerImg.png';
import logo from '../../assets/images/osiz_lab-1.png';
import fbIcon from '../../assets/images/fbIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.svg';
import linkedIcon from '../../assets/images/linkedIcon.svg';
import instaIcon from '../../assets/images/instaIcon.svg';
import youtubeIcon from '../../assets/images/youtubeIcon.svg';
import mediumIcon from '../../assets/images/mediumIcon.svg';
import pinterestIcon from '../../assets/images/pinterestIcon.svg';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { CourseAction } from "../../Redux/actions/CourseAction";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const Footer = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch();
   const [getSiteSettings, setSiteSettings] = useState('')
   const [getFooterText, setFooterText] = useState('')
   const [courseName, setCourseName] = useState('')
   const [skeletonLoader, setSkeletonLoader] = useState(false);

   useEffect(() => {
      setSkeletonLoader(true);
      dispatch(userAction.GetSiteSettings({}, (resp) => {
         try{
            if(resp.data.error == false){
               setSiteSettings(resp.data.resp[0])
            }
         }
         catch(e){
            // display a catch error
         }
      }))
      dispatch(userAction.GetSingleCms({
         id :"6572b263ef7b79095ac758ba"
      },(resp)=>{
          try{
              if(resp.data.status === true){
               setFooterText(resp.data.resp)
               setSkeletonLoader(false);
              }
          }
          catch(e){
            // display a catch error
          }
      }
      ))
      dispatch(CourseAction.CourseList({},(resp)=>{
         try{
             if(resp.data.status === true){
                 setCourseName(resp.data.resp)
             }
         }
         catch(e){
             // display a catch error
         }
     }
     ))

     window.scrollTo(0, 0)
     
   }, [])
   
   
   return (
      <>
         <footer>
            <div className="container">
               <div className="purpleCnt">
                  <div className="row align-items-center">
                     <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end order-2 order-lg-1">
                        <img src={footerImg} className="img-fluid footerImg" />
                     </div>
                     <div className="col-lg-6 pl-lg-0 pt-5 order-1 order-lg-2 text-center text-lg-left">
                     { skeletonLoader ? 
                     <SkeletonTheme baseColor="#07070f" highlightColor="#9B6FF8">
                        <div className="ndprall">
                        <h3 className="heading mb-0"><Skeleton width="25rem" height="4rem" /></h3>
                        <p className="my-4 ulbult"><Skeleton width="20rem" height="1rem" /></p>
                        <p className="my-4 ulbult"><Skeleton width="15rem" height="1rem" /></p>
                        <Skeleton width="6rem" height="2rem" />
                        </div> </SkeletonTheme> :
                        <div className="ndprall">
                        <h3 className="heading mb-0">{getFooterText.title}</h3>
                        <p className="my-4 ulbult"><div dangerouslySetInnerHTML={{__html: getFooterText.content  || 'No Data Found'}}></div></p>
                        <a className="btn whiteBtn" onClick={()=>navigate("/contact")}>Join Us</a>
                        </div> }
                     </div>
                  </div>
               </div>
               <div className="row ">
                  <div className="col-12  mb-5">
                     <img src={logo} width="150px" className="img-fluid" />
                  </div>
                  <div className="col">
                     <ul>
                        <li className="colHeading">Courses</li>
                        { courseName && courseName.length > 0 ? courseName.map((d)=>(
                        <li><a onClick={()=>navigate(`/coursedetails/${d._id}`)} >{d.coursetitle}</a></li>)) : <li><a>No Courses !</a></li> }
                        {/* <li><a>React JS</a></li>
                        <li><a>PHP</a></li>
                        <li><a>Java Script</a></li> */}
                     </ul>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 col-xl">
                     {/* <ul>
                        <li className="colHeading opacity0">Courses</li>
                        <li><a>Python</a></li>
                        <li><a>Java</a></li>
                        <li><a>HTML</a></li>
                     </ul> */}
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 col-xl">
                     <ul>
                        <li className="colHeading">Company</li>
                        <li onClick={()=>{navigate('/aboutus')}}><a>About Us</a></li>
                        {/* <li><a>Courses</a></li>
                        <li><a>Trainers</a></li> */}
                        <li  onClick={()=>{navigate('/contact')}}><a>Contact Us</a></li>
                     </ul>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 col-xl">
                     <ul>
                        <li className="colHeading">Resources</li>
                        {/* <li><a>Blog</a></li>
                        <li><a>FAQ</a></li> */}
                        <li onClick={()=>{navigate('/terms')}}><a>Terms and Conditions</a></li>
                        <li onClick={()=>{navigate('/privacy')}}><a >Privacy Policy</a></li>
                     </ul>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 col-xl">
                     <ul>
                        <li className="colHeading">Follow Us</li>
                        <div className="d-flex flex-wrap align-items-center">

                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.facebook} target="_blank">
                                 <img className="cursor-pointer" src={fbIcon} alt="" />
                              </a>
                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.twitter} target="_blank">
                                 <img className="cursor-pointer" src={twitterIcon} alt="" />
                              </a>

                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.linkedin} target="_blank">
                                 <img className="cursor-pointer" src={linkedIcon} alt="" />
                              </a>

                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.instagram} target="_blank">
                                 <img className="cursor-pointer" src={instaIcon} alt="" />
                              </a>

                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.youtube} target="_blank">
                                 <img className="cursor-pointer" src={youtubeIcon} alt="" />
                              </a>

                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.medium} target="_blank">
                                 <img className="cursor-pointer" src={mediumIcon} alt="" />
                              </a>

                           </div>
                           <div className="socialIcon">
                              <a href={getSiteSettings && getSiteSettings.pinterest} target="_blank">
                                 <img className="cursor-pointer" src={pinterestIcon} alt="" />
                              </a>

                           </div>
                        </div>
                     </ul>
                  </div>
                  <div className="col-12">
                     <hr />
                  </div>
                  <div className="col-12 text-center py-3">
                     {/* <p className="copyRights">© 2023 Osiz Labs All rights reserved.</p> */}
                     <p className="copyRights">{getSiteSettings ? getSiteSettings.copyright : "No Data Found"}</p>
                  </div>
               </div>
            </div>
         </footer>
      </>
   )
}
export default Footer;