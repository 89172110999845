import React, { Fragment, useState, useEffect } from "react";
import contactBanner from '../../assets/images/contactBanner.png';
import locationIcon from '../../assets/images/locationIcon.svg';
import phoneIcon from '../../assets/images/phoneIcon.svg';
import mailIcon from '../../assets/images/mailIcon.svg';
import fbIcon from '../../assets/images/fbIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.svg';
import linkedIcon from '../../assets/images/linkedIcon.svg';
import instaIcon from '../../assets/images/instaIcon.svg';
import youtubeIcon from '../../assets/images/youtubeIcon.svg';
import mediumIcon from '../../assets/images/mediumIcon.svg';
import pinterestIcon from '../../assets/images/pinterestIcon.svg';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Aos from "aos";
import Swal from "sweetalert2";
import Helmets from "../../components/helmet/Helmets";

const Contactus = (props) => {
    const dispatch = useDispatch()
    const [getCourse, setCourse] = useState('')
    const [loader, setLoader] = useState(false)
    const [getSiteSettings, setSiteSettings] = useState('')
    const [getBatch, setBatch] = useState('')

    useEffect(() => {
        dispatch(userAction.GetCourseList({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setCourse(resp.data.resp)
                }
                else{
                    // display a api reponse error
                }
            }
            catch(e){
                // display a catch error
            }
        }))
        dispatch(userAction.GetSiteSettings({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setSiteSettings(resp.data.resp[0])
                }
                else{
                    // display a api reponse error
                }
            }
            catch(e){
                // display a catch error
            }
        }))

        const joiningBatchList = [
            {
              id: "1",
              batch: "Immediately",
            },
            {
              id: "2",
              batch: "In 2 weeks",
            },
            {
              id: "3",
              batch: "In a month",
            },
          ];

          setBatch(joiningBatchList)

        Aos.init({ once: true });

       window.scrollTo(0, 0);


    }, [])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Name is required')
            .matches(/^[A-Za-z ]*$/, 'Please enter alphabets')
            .max(30)
            .min(3, "Must be more than 2 characters"),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .trim(),
        batchtype: Yup.string()
            .required('Batch is required')
            .trim(),
        course: Yup.string()
            .required('Course is required')
            .trim(),
        phonenumber: Yup.string()
            .required('Phone number is required')
            .matches(/^(?!(\d)\1{9})[6,7,8,9]\d{9}$/, 'only accept Indian phone numbers')
            .trim(),
    });

    const formOptions = { resolver: yupResolver(validationSchema), mode: "onChange", };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        
        setLoader(true)
        dispatch(userAction.AddContact({
            name: data.name,
            email: data.email,
            phonenumber: data.phonenumber,
            coursename: data.course,
            batch: data.batchtype
        }, (resp) => {
            try{
                if (resp.data.error == false) {
                    Swal.fire({
                        title: "Submission successful ! Thank you for contacting us, We'll get back to you shortly.",
                        icon: "success",
                        showClass: {
                          popup: 'animate__animated animate__zoomIn'
                        },
                        hideClass: {
                          popup: 'animate__animated animate__fadeOutDown'
                        },
                        confirmButtonColor: "#9B6FF8",
                        confirmButtonText: "Okay",
                      }).then(
                        (result) => {
                          if (result.isConfirmed) {
                            reset()
                            setLoader(false)    
                          }
                        }
                      );
                }
                else {
                    toast.error(resp.data.message)
                    reset()
                    setLoader(false)
                }
            }
            catch(e){
                // display a catch error
            }
        }))
    }

    
  

  

    return (
        <Fragment>
            < Helmets title={"Contact Us"} description ={"Osiz Labs Contact Us Page"} />
            <section className="cmsSecCnt mb-5"  >
                <div className="cmsBanner contactBanner">
                    <img src={contactBanner} className="cmsBannerImg" />
                    <div className="container pt-5">
                        <h3 className="cmsHeading text-left">Contact Us</h3>
                        <p className="cmsSubHeading">Get in touch with us</p>
                    </div>
                </div>
                <div className="cmsTextCnt p-0" >
                    <div className="container" >
                        <div className="contactFormCnt d-flex flex-wrap" data-aos="zoom-in" data-aos-duration="1000">
                            <div className="contactLeftCnt">
                                <div className="formCnt">
                                    <h3 className="heading text-left">Send us a message</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <input type="text"
                                                    autoComplete="off" 
                                                    placeholder=""
                                                    maxLength={50}
                                                    {...register('name')}
                                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    id="inputname" />
                                                <label for="inputname">Name <span >*</span></label>
                                                <div className="invalid-feedback errorColor">{errors.name?.message}</div>

                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <input type="email"
                                                    autoComplete="off"
                                                    maxLength={50}
                                                    placeholder=""
                                                    {...register('email')}
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="inputemail" />
                                                <label for="inputemail">Email <span >*</span></label>
                                                <div className="invalid-feedback errorColor"  >{errors.email?.message}</div>

                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <input type="text"
                                                    autoComplete="off"
                                                    maxLength={10}
                                                    placeholder=""
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    {...register('phonenumber')}
                                                    className={`form-control ${errors.phonenumber ? 'is-invalid' : ''}`}
                                                    id="inputphone" />
                                                <label for="inputphone">Phone Number <span >*</span></label>
                                                <div className="invalid-feedback errorColor">{errors.phonenumber?.message}</div>

                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                            <select name="course" {...register("course")} 
                                            className={`${ errors.course ? "is-invalid" : ""}`}>
                                                <option selected="true" value="">{"Select Course"} <span >*</span> </option>
                                                {getCourse && getCourse.map((d) => <option value={d.coursetitle}>{d.coursetitle}</option>)}
                                            </select>
                                            <div className="invalid-feedback errorColor">{errors.course?.message}</div>                       
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                            <select name="batchtype" {...register("batchtype")} 
                                            className={`${ errors.batchtype ? "is-invalid" : ""}`}>
                                                <option  selected="true" value="">{"Select the Batch"} <span >*</span> </option>
                                                <option style={{fontWeight:"bold"}}  disabled value="">{"Planning to start Training"} </option>
                                                {getBatch && getBatch.map((d) => <option value={d.batch}>{d.batch}</option>)}
                                            </select>
                                            <div className="invalid-feedback errorColor">{errors.batchtype?.message}</div>                       
                                            </div>
                                        </div>
                                        {loader ?
                                            <button className="btn whiteBtn">
                                                <Spinner animation="border" size="sm" className="mr-2" />Loading...</button> :
                                            <button type="submit" className="btn whiteBtn">Send</button>
                                        }
                    

                                    </form>
                                   
                                </div>
                            </div>
                            <div className="contactRightCnt">
                                <div className="formCnt d-flex flex-column align-items-start h-100">
                                    <h3 className="heading text-left">Contact Information</h3>
                                    <div className="d-flex justify-content-start align-items-start mb-3">
                                        <img src={locationIcon} className="mr-3" />

                                        <p>{getSiteSettings ? getSiteSettings.address : "No Data Found"}</p>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-start mb-3">
                                        <img src={phoneIcon} className="mr-3" />
                                        <p>{getSiteSettings ? getSiteSettings.contactNumber : "No Data Found"}</p>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-start mb-3">
                                        <img src={mailIcon} className="mr-3" />
                                        <p>{getSiteSettings ? getSiteSettings.contactMail : "No Data Found"}</p>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center mt-auto">


                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.facebook} target="_blank">
                                                <img className="cursor-pointer" src={fbIcon} alt="" />
                                            </a>
                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.twitter} target="_blank">
                                                <img className="cursor-pointer" src={twitterIcon} alt="" />
                                            </a>

                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.linkedin} target="_blank">
                                                <img className="cursor-pointer" src={linkedIcon} alt="" />
                                            </a>

                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.instagram} target="_blank">
                                                <img className="cursor-pointer" src={instaIcon} alt="" />
                                            </a>

                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.youtube} target="_blank">
                                                <img className="cursor-pointer" src={youtubeIcon} alt="" />
                                            </a>

                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.medium} target="_blank">
                                                <img className="cursor-pointer" src={mediumIcon} alt="" />
                                            </a>

                                        </div>
                                        <div className="socialIcon">
                                            <a href={getSiteSettings && getSiteSettings.pinterest} target="_blank">
                                                <img className="cursor-pointer" src={pinterestIcon} alt="" />
                                            </a>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );

}

export default Contactus;