import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Collapse, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import classnames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import downloadImg from '../../assets/images/download.png';
import downarrImg from '../../assets/images/downarr.png';
import coursebannerminiImg from '../../assets/images/coursebannermini.png';
import frame1Img from '../../assets/images/frame1.png';
import frame2Img from '../../assets/images/frame2.png';
import frame3Img from '../../assets/images/frame3.png';
import frame4Img from '../../assets/images/frame4.png';
import frame5Img from '../../assets/images/frame5.png';
import frame6Img from '../../assets/images/frame6.png';
import DevOpsCertified from '../../assets/images/DevOpsCertified.svg';
import DevOpsProgram from '../../assets/images/DevOpsProgram.svg';

import { CourseAction } from "../../Redux/actions/CourseAction";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { userAction } from "../../Redux/actions/userAction";
import CourseEnquiry from "./CourseEnquiry";
import Aos from "aos";
import Helmets from "../../components/helmet/Helmets";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const CourseDetails = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleColp = () => setIsOpen(!isOpen);

    const [isOpen1, setIsOpen1] = useState(false);
    const toggleColp1 = () => setIsOpen1(!isOpen1);

    const [isOpen2, setIsOpen2] = useState(false);
    const toggleColp2 = () => setIsOpen2(!isOpen2);

    const [isOpen3, setIsOpen3] = useState(false);
    const toggleColp3 = () => setIsOpen3(!isOpen3);

    const [isOpen4, setIsOpen4] = useState(false);
    const toggleColp4 = () => setIsOpen4(!isOpen4);

    const [isOpen5, setIsOpen5] = useState(false);
    const toggleColp5 = () => setIsOpen5(!isOpen5);

    const Star = () => {
        return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                <g clip-path="url(#clip0_72_1815)">
                    <path d="M4.34965 3.36413L1.42548 3.78809L1.37369 3.79863C1.29529 3.81944 1.22382 3.86069 1.16657 3.91817C1.10932 3.97564 1.06835 4.04727 1.04785 4.12576C1.02734 4.20424 1.02803 4.28676 1.04985 4.36489C1.07166 4.44302 1.11382 4.51396 1.17203 4.57046L3.29044 6.63251L2.79086 9.54521L2.7849 9.59563C2.7801 9.67672 2.79694 9.75763 2.83369 9.83007C2.87044 9.90251 2.92579 9.96389 2.99405 10.0079C3.06232 10.0519 3.14107 10.077 3.22222 10.0806C3.30337 10.0842 3.38402 10.0661 3.4559 10.0283L6.07115 8.6533L8.68044 10.0283L8.72628 10.0494C8.80193 10.0792 8.88415 10.0883 8.9645 10.0759C9.04485 10.0634 9.12043 10.0298 9.18351 9.97846C9.24658 9.92715 9.29486 9.85997 9.32341 9.78384C9.35195 9.7077 9.35973 9.62535 9.34594 9.54521L8.8459 6.63251L10.9652 4.57001L11.001 4.53105C11.0521 4.46815 11.0855 4.39284 11.098 4.31279C11.1105 4.23274 11.1016 4.1508 11.0721 4.07534C11.0426 3.99988 10.9936 3.93358 10.9302 3.8832C10.8667 3.83282 10.791 3.80016 10.7109 3.78855L7.78669 3.36413L6.47953 0.714965C6.4417 0.638209 6.38315 0.573576 6.31049 0.528379C6.23783 0.483183 6.15397 0.459229 6.0684 0.459229C5.98283 0.459229 5.89897 0.483183 5.82631 0.528379C5.75366 0.573576 5.6951 0.638209 5.65728 0.714965L4.34965 3.36413Z" fill="#FFBA2E"/>
                </g>
                <defs>
                    <clipPath id="clip0_72_1815">
                    <rect width="11" height="11" fill="white" transform="translate(0.571533)"/>
                    </clipPath>
                </defs>
            </svg>
        </>
      )
    }
    const StarUnfill = () => {
        return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_72_1694)">
                <path d="M4.8084 4.28162L1.08674 4.82121L1.02082 4.83462C0.921033 4.86111 0.830066 4.91361 0.757206 4.98676C0.684346 5.0599 0.632204 5.15107 0.606105 5.25096C0.580006 5.35085 0.580885 5.45588 0.608652 5.55531C0.636418 5.65475 0.690079 5.74504 0.764152 5.81695L3.46032 8.44137L2.82449 12.1485L2.8169 12.2126C2.81079 12.3158 2.83222 12.4188 2.879 12.511C2.92577 12.6032 2.99621 12.6813 3.0831 12.7373C3.16999 12.7934 3.2702 12.8253 3.37349 12.8299C3.47677 12.8344 3.57941 12.8114 3.6709 12.7633L6.9994 11.0133L10.3203 12.7633L10.3787 12.7901C10.4749 12.828 10.5796 12.8397 10.6818 12.8238C10.7841 12.808 10.8803 12.7652 10.9606 12.6999C11.0409 12.6345 11.1023 12.5491 11.1386 12.4522C11.175 12.3553 11.1849 12.2504 11.1673 12.1485L10.5309 8.44137L13.2282 5.81637L13.2737 5.76679C13.3387 5.68674 13.3813 5.59089 13.3972 5.489C13.4131 5.38712 13.4017 5.28284 13.3642 5.1868C13.3267 5.09075 13.2644 5.00637 13.1836 4.94225C13.1028 4.87813 13.0065 4.83657 12.9045 4.82179L9.18282 4.28162L7.51915 0.909955C7.47101 0.812267 7.39649 0.730005 7.30401 0.672482C7.21154 0.61496 7.10481 0.584473 6.9959 0.584473C6.887 0.584473 6.78027 0.61496 6.68779 0.672482C6.59532 0.730005 6.52079 0.812267 6.47265 0.909955L4.8084 4.28162Z" fill="white" fill-opacity="0.47"/>
                </g>
                <defs>
                <clipPath id="clip0_72_1694">
                <rect width="14" height="14" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </>
      )
    }


    let dispatch = useDispatch();
    let {_id} = useParams()
    const [courseDetails, setCourseDetails] = useState('')
    const navigate = useNavigate()
    const [courseBanners, setCourseBanners] = useState('')
    const [skeletonloader, setSkeletonLoader] = useState(false)

    useEffect(()=>{
     setSkeletonLoader(true)
     dispatch(CourseAction.SingleCourseGet({
        id :_id
     },(resp)=>{
         try{
             if(resp.data.status === true){
                setCourseDetails(resp.data.resp[0])
                setSkeletonLoader(false)
             }
             else{
                setSkeletonLoader(false)
                // display a api reponse error
             }
         }
         catch(e){
                setSkeletonLoader(false)
                // display a catch error
         }
     }
     ))

     dispatch(userAction.ListCms({}, (resp) => {
        try{
            if(resp.data.status === true){
                setCourseBanners(resp.data.resp)
            }
            else{
                // display a api reponse error
            }
        }
        catch(e){
            // display a catch error
        }
    }))

    Aos.init({ once: true });

     window.scrollTo(0, 0);

    },[_id])


    const CourseCMSBanner = courseBanners && courseBanners.find(d => d._id === "657983504a39b098edec3dd7")




    return (
        <Fragment>
            < Helmets title={courseDetails && courseDetails.coursetitle + " Course Details"} description ={"Osiz Labs" + courseDetails && courseDetails.coursetitle + " Course Details Page"} />
            <section className="CrsBnrDv">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 mb-2">
                        <div className="BrdCrmps">
                        <span>Courses / {courseDetails && courseDetails.coursetitle } Programming</span>                        
                    </div>
                    {skeletonloader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                            <Skeleton width="85%" height="8%"  />
                            <br />
                            <Skeleton width="73%" height="10%"  />
                            <br />
                            <Skeleton width="60%" height="5%"  />
                            <br />
                            <Skeleton width="40%" height="3%"  />
                            <Skeleton width="40%" height="3%"  />
                            <Skeleton width="40%" height="3%"  />
                            <Skeleton width="40%" height="3%"  />
                            <Skeleton width="40%" height="3%"  />
                         </SkeletonTheme> : <>
                    <h3>{ courseDetails && courseDetails.courses ? courseDetails.courses.subtitle : "No Data Found"}</h3>
                    <p><div dangerouslySetInnerHTML={{__html: courseDetails && courseDetails.courses && courseDetails.courses.subdescription.replaceAll("&lt;", "<") }}></div>
                        </p> </>}
                        
                    <div className="TrdRatDv mt-5">
                        
                        {/* <button onClick={()=>navigate('/contact')} className="TrdBtn btn">Talk to our trainers</button> */}
                        { !skeletonloader &&
                        <div className="StrDv mx-2">
                            <h3 className="heading">{CourseCMSBanner && CourseCMSBanner.title  }</h3>
                        <div dangerouslySetInnerHTML={{ __html: CourseCMSBanner && CourseCMSBanner.content  }}></div>
                            {/* <span className="mr-2">4.5</span>
                            <Star />
                            <Star />
                            <Star />
                            <Star />
                            <StarUnfill /> */}
                        </div> }
                        <div className="TrnrDv">
                            {/* <span>(13,000 ratings)</span>
                            <h6>23,246 students</h6> */}
                        </div>
                    </div>
                    <div className="TrnrDv mt-4">
                        {/* <span>Trainers : </span>
                        <h6>Tim Bulchaka , Lara Martin</h6> */}
                    </div>   

                        </div>
                        <div className="col-xl-4 mb-2" >
                            
                            <div className="CrsStrTpDv" data-aos="fade-left" data-aos-duration="1000">
                            
                            <div className="CrsStrQkEqry">
                            <CourseEnquiry />
                                    {/* <h6>Quick Enquiry</h6>
                                    <div className="form-group psrel ">
                                        <input type="text" className="form-control" placeholder="" />
                                           <label for="inputname">Name*</label>
                                    </div>
                                    <div className="form-group psrel">
                                        <input type="text" className="form-control" placeholder="" />
                                        <label for="inputname">Email*</label>
                                    </div>
                                    <div className="form-group d-flex psrel">
                                        <select className="form-control mr-2" style={{width: '65px'}}>
                                        <option>91</option>
                                        <option>92</option>
                                        <option>93</option>
                                        </select>
                                        <input type="text" className="form-control" placeholder="90154XXX24" />
                                    </div>
                                    <div className="form-group psrel">
                                        <select className="form-control">
                                        <option>Select the course</option>
                                        <option>JAVA</option>
                                        <option>REACT JS</option>
                                        </select>
                                    </div>
                                    <div className="form-group psrel">
                                        <select className="form-control">
                                        <option>Select the Batch</option>
                                        <option>Batch 1</option>
                                        <option>Batch 2</option>
                                        </select>
                                    </div>
                                    <button className="btn commonBtn w-100" style={{marginTop:'10px'}}>Enquire Now</button> */}
                                </div>

                            </div>
                        

                        </div>
                    </div>
                                 
                </div>
            </section>
            {/* <section className="CrsLrnDv">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mt-4" > */}
                                {/* <div className="CrsWtWlLrn mt-5">
                                    <h4>What you'll learn</h4>
                                    <div className="row CrsWtWlLrnDtDv">
                                        <div className="col-lg-6">
                                            <div className="CrsWtWlLrnDt">
                                                <ul>                                          
                                                    <li><span>Learn the core Java skills needed to apply for Java developer positions in just 14 hours.</span></li>
                                                </ul>
                                            </div>
                                            <div className="CrsWtWlLrnDt">
                                                <ul>                                          
                                                    <li><span>Be able to demonstrate your understanding of Java to future employers.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="CrsWtWlLrnDt">
                                                <ul>                                          
                                                    <li><span>Be able to demonstrate your understanding of Java to future employers.</span></li>
                                                </ul>                                        
                                            </div>
                                            <div className="CrsWtWlLrnDt">
                                                <ul>                                          
                                                    <li><span>Learn industry "best practices" in Java software development from a professional Java developer who has worked in the language for close to 25 years.</span></li>
                                                </ul> 
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="CrsSylBs">
                                    <div className="CrsSylBsHd">
                                        <div className="CrsSyBlsHdL">
                                            <h3>Java Course Syllabus</h3>
                                            <h6>
                                                <span>46 sessions</span>
                                                <span>
                                                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <circle cx="2" cy="2" r="2" fill="#9B6FF8"/>
                                                    </svg>
                                                    120 lectures</span>
                                                <span>
                                                    <svg  className="mr-2" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <circle cx="2" cy="2" r="2" fill="#9B6FF8"/>
                                                    </svg>
                                                    136h 56m total length</span>
                                            </h6>
                                        </div>
                                        <div className="CrsSyBlsHdR">
                                            <button className="btn DwnldSylsBtn">Download Syllabus<img src={downloadImg} className="ml-2"/></button>
                                        </div>
                                    </div>
                                    <div className="CrsSylBsDv">                                
                                       { courseDetails && courseDetails.courses && courseDetails.courses.syllabus.length > 0 ? courseDetails.courses.syllabus.map((d)=> 
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>{d.syllabustitle}</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen}>
                                                <ul>
                                                    <div dangerouslySetInnerHTML={{__html: d.SyllabusDescription.replaceAll("&lt;", "<") }}></div>
                                                </ul>
                                            </Collapse>
                                        </div>
                                            ) : "No Record Found" }
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Core Java Programming -<br/>Introducing to Java</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp1}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Data Types</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen1}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp2}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Operators</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen2}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp3}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Variables</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen3}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp4}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Methods</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen4}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                        <div className="CrsSylBsMd">
                                            <div className="CrsSylBsMdTit" onClick={toggleColp5}>
                                                <div className="CrsSylBsMdTitL">
                                                    <img src={downarrImg} className="mr-2"/>
                                                    <h5>Object</h5>
                                                </div>
                                                <div className="CrsSylBsMdTitR">
                                                    <span>120 lectures</span>
                                                    <span>136h 56m total length</span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={isOpen5}>
                                                <ul>
                                                    <li>Primitive data types</li>
                                                    <li>Keywords, Identifiers, Expressions </li>
                                                    <li>Reference Data Types</li>
                                                    <li>Arithmetic Operators</li>
                                                </ul>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>                         */}
                        {/* </div>
                        <div className="col-lg-4">
                            <div className="CrsStrTpDv"> */}
                                {/* <div className="CrsStrCdngDv">
                                    <img src={coursebannerminiImg} className="w-100"/>
                                    <div className="CrsStrCdngDvDts">
                                        <h4>Java 17 Masterclass: Start<br/>Coding in 2023</h4>
                                        <button className="btn commonBtn w-100">Enquire Now</button>
                                        <h5>This course includes:</h5>
                                        <ul>
                                            <li>
                                                <img src={frame1Img} className="mr-2" />
                                                <span>134.5 hours on-demand video</span>
                                            </li>
                                            <li>
                                                <img src={frame2Img} className="mr-2" />
                                                <span>50 coding exercises</span>
                                            </li>
                                            <li>
                                                <img src={frame3Img} className="mr-2" />
                                                <span>3 articles</span>
                                            </li>
                                            <li>
                                                <img src={frame4Img} className="mr-2" />
                                                <span>318 downloadable resources</span>
                                            </li>
                                            <li>
                                                <img src={frame5Img} className="mr-2" />
                                                <span>Access on Devices</span>
                                            </li>
                                            <li>
                                                <img src={frame6Img} className="mr-2" />
                                                <span>Certificate of completion</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                                
                            {/* </div>
                        </div>
                    </div>
                </div>
            </section> */}
              {skeletonloader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                               <section className="courseLightSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">
                            <h3 className="secHeading">     <Skeleton width="73%" height="10%"  /></h3>
                            <Skeleton width="60%" height="5%"  />
                            </div>                            
                        </div>
                        <div className="col-lg-6">
                        <Skeleton width="100%" height="100%"  />
                        </div>
                    </div>
                </div>
            </section>
                            
                         </SkeletonTheme> : 
            <section className="courseLightSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">
                            <h3 className="secHeading">{ courseDetails && courseDetails.courses ? courseDetails.courses.certificatetitle : "No Data Found"}</h3>
                            <p dangerouslySetInnerHTML={{__html: courseDetails && courseDetails.courses && courseDetails.courses.certificatedescription.replaceAll("&lt;", "<") }}></p>
                            </div>                            
                        </div>
                        <div className="col-lg-6">
                            <img width="300px" height="300px" src={courseDetails && courseDetails.courses && courseDetails.courses.certificateimages || DevOpsCertified } className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>}
            {skeletonloader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                              <section className="courseDarkSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <Skeleton width="100%" height="100%"  />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                            <h3 className="secHeading"><Skeleton width="73%" height="10%"  /></h3>
                            <Skeleton width="60%" height="5%"  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                            
                         </SkeletonTheme> : 
            <section className="courseDarkSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <img width="300px" height="300px" src={courseDetails && courseDetails.courses && courseDetails.courses.aboutimages || DevOpsProgram } className="img-fluid" />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div>
                            <h3 className="secHeading">{ courseDetails && courseDetails.courses ? courseDetails.courses.aboutcoursetitle : "No Data Found"}</h3>
                            <p dangerouslySetInnerHTML={{__html: courseDetails && courseDetails.courses && courseDetails.courses.aboutcoursedescription.replaceAll("&lt;", "<") }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </Fragment>
    );
   
}

export default CourseDetails;