import * as apiHelper from '../../views/helper/ApiHelper'

export const CourseService = {
  CourseList,
  SingleCourseGet,

};


async function CourseList(data) {
  try {
    const api = await apiHelper.getRequest(`user/getcourse`, data);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}

async function SingleCourseGet(data) {
  try {
    const api = await apiHelper.postRequest(`user/courseGetId?id=${data.id}`);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}