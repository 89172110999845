import React, { Fragment, useState, useEffect  } from "react";
import privacyBanner from '../../assets/images/privacyBanner.png';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Aos from "aos";
import Helmets from "../../components/helmet/Helmets";

const Terms = (props) => {   
    const dispatch = useDispatch();
    const [getData, setData] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
       dispatch(userAction.GetSingleCms({
        id: "656f1a8d67a996f60a921ccb",
       }, (resp) => {
        try{
            if(resp.data.status === true){
                setData(resp.data.resp.content)
                setLoader(false)
            }
            else{
                setLoader(false)
                // display a api reponse error
            }
        }
        catch(e){
            setLoader(false)
            // display a catch error
        }
       }))
       
       window.scrollTo(0, 0);

       Aos.init({ once: true });

    }, [])

    return (
        <Fragment>
            < Helmets title={"Privacy and Policy"} description ={"Osiz Labs Privacy and Policy Page"} />
            <section className="cmsSecCnt">
                <div className="cmsBanner d-flex justify-content-center align-items-center">
                    <img src={privacyBanner} className="cmsBannerImg" />
                    <div className="container">
                        <h3 className="cmsHeading">Privacy and Policy</h3>
                    </div>
                </div>
                <div className="cmsTextCnt">
                    <div className="container">
                    {loader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                            <Skeleton width="100%" height="100%"  />
                            <Skeleton width="80%" height="50%"  />
                         </SkeletonTheme> :
                         <div data-aos="zoom-out-up" data-aos-duration="1000">
                    <p dangerouslySetInnerHTML={{__html: getData  || 'No Data Found'}}></p> </div>}
                    </div>
                </div>
            </section>
        </Fragment>
    );
   
}

export default Terms;