import { userService } from "../services";
import { userConstant } from "../constants";
import { baseAction } from "./baseAction";
const { request, failure, success } = baseAction;

export const userAction = {
  ListCms,
  GetSingleCms,
  GetSiteSettings,
  GetCourseList,
  AddContact
};

function ListCms(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await userService.ListCms(data);
      callback(resp);
      dispatch(success(userConstant.LISTCMS, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}
function GetSingleCms(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await userService.GetSingleCms(data);
      callback(resp);
      dispatch(success(userConstant.GETSINGLECMS, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}
function GetSiteSettings(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await userService.GetSiteSettings(data);
      callback(resp);
      dispatch(success(userConstant.GETSITESETTING, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}
function GetCourseList(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await userService.GetCourseList(data);
      callback(resp);
      dispatch(success(userConstant.GETCOURSELIST, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}
function AddContact(data = {}, callback = () => {}) {
  return async (dispatch) => {
    try {
      let resp = await userService.AddContact(data);
      callback(resp);
      dispatch(success(userConstant.ADDCONTACT, resp.data));
    } catch (e) {
      // API Response Catch Error
    }
  };
}
