import { combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import navigation from "./reducers/navigation";
import auth from "./reducers/auth";

const rootReducer = combineReducers({
    auth,
    navigation,
    User: userReducer,

});

export default rootReducer;