import { userConstant } from "../constants";

const initialState = {
  login: null,
  activate_status: localStorage.getItem("isloggin") === "true",
};

export default function (state = initialState, action) {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {

    case userConstant.LISTCMS:
      return {
        ...state,
        ListCms: action.data,
      };
    case userConstant.GETSINGLECMS:
      return {
        ...state,
        GetSingleCms: action.data,
      };
    case userConstant.GETSITESETTING:
      return {
        ...state,
        GetSiteSettings: action.data,
      };
    case userConstant.GETCOURSELIST:
      return {
        ...state,
        GetCourseList: action.data,
      };
      case userConstant.ADDCONTACT:
        return {
          ...state,
          AddContact: action.data,
        };
  
    default:
      return state;
  }
}
