import React, {  Fragment, useState, useEffect  } from "react";
import aboutbannerImg from '../../assets/images/aboutbanner.png';
import about1Img from '../../assets/images/about1.png';
import about2Img from '../../assets/images/about2.png';
import user1Img from '../../assets/images/user1.png';
import user2Img from '../../assets/images/user2.png';
import quoteImg from '../../assets/images/quote.png';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Aos from "aos";
import Helmets from "../../components/helmet/Helmets";

const AboutUs = (props) => {

    const dispatch = useDispatch();
    const [getData, setData] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
       dispatch(userAction.GetSingleCms({
        id: "656f1313f4c01ee9ba6d7815",
       }, (resp) => {
        try{
            if(resp.data.status === true){
                setData(resp.data.resp.content)
                setLoader(false)
            }
            else{
                setLoader(false)
                // display a api reponse error
            }
        }
        catch(e){
            setLoader(false)
            // display a catch error
        }
       }))
       
       Aos.init({ once: true });

       window.scrollTo(0, 0);

    }, [])

    return (
        <Fragment>
            < Helmets title={"About Us"} description ={"Osiz Labs About Us Page"} />
            <section className="AbtBnrDv">
                <div className="container">
                    <div className="row align-items-center pt-2">
                        <div className="col-lg-6">
                            <div className="AbtBnrDvL">
                                <h3>About Us</h3>
                                <p>We share knowledge with the world</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={aboutbannerImg} className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="AbtLrnDv">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-lg-7">
                        {loader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                            <Skeleton width="100%" height="100%"  />
                            <Skeleton width="80%" height="50%"  />
                         </SkeletonTheme> :
                             <div data-aos="zoom-out-up" data-aos-duration="1000">
                            <p dangerouslySetInnerHTML={{__html: getData  || 'No Data Found'}}></p> </div> }
{/* 
                            <div className="AbtLrnDvDtsL">
                                <img src={about1Img} className="img-fluid mr-3"/>
                                <div className="AbtLrnDvDtsLCt">
                                    <h4>Rica Martin</h4>
                                    <h5>Senior Trainer</h5>
                                    <p>Whether you want to learn or to share what you know, you’ve come to the right place. As a global destination for online learning, we empower organizations and individuals with flexible and effective skill development.</p>
                                </div>
                            </div>
                            <div className="AbtLrnDvDtsL">
                                <div className="AbtLrnDvDtsLCt mr-3">
                                    <h4>Henry Steward</h4>
                                    <h5>Full stack trainee</h5>
                                    <p>Whether you want to learn or to share what you know, you’ve come to the right place. As a global destination for online learning, we empower organizations and individuals with flexible and effective skill development.</p>
                                </div>
                                <img src={about2Img} className="img-fluid"/>                                
                            </div> */}
                        </div>
                        {/* <div className="col-lg-5">
                            <div className="CrsStrQkEqry">
                                <h6>Quick Enquiry</h6>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Name*" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email*" />
                                </div>
                                <div className="form-group d-flex">
                                    <select className="form-control mr-2" style={{width: '65px'}}>
                                    <option>91</option>
                                    <option>92</option>
                                    <option>93</option>
                                    </select>
                                    <input type="text" className="form-control" placeholder="90154XXX24" />
                                </div>
                                <div className="form-group">
                                    <select className="form-control">
                                    <option>Select the course</option>
                                    <option>JAVA</option>
                                    <option>REACT JS</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select className="form-control">
                                    <option>Select the Batch</option>
                                    <option>Batch 1</option>
                                    <option>Batch 2</option>
                                    </select>
                                </div>
                                <button className="btn commonBtn w-100" style={{marginTop:'50px'}}>Enquire Now</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <section className="AbtGrwDv">
                <div className="container">
                    <h3>We just keep growing</h3>
                    <p>Our global community and our course catalog get bigger every day. Check<br/> out our latest numbers as of December 2023.</p>
                    <div className="AbtGrwCnt">
                        <div className="AbtGrwCntDts">
                            <h4>67M<span>+</span></h4>
                            <p>Learners</p>
                        </div>
                        <div className="AbtGrwCntDts">
                            <h4>75K<span>+</span></h4>
                            <p>Instructors</p>
                        </div>
                        <div className="AbtGrwCntDts">
                            <h4>210K<span>+</span></h4>
                            <p>Courses</p>
                        </div>
                        <div className="AbtGrwCntDts">
                            <h4>15K<span>+</span></h4>
                            <p>Enterprise customers</p>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="AbtFwOdDv">
                <div className="container">
                    <h3>Few Words About Us</h3>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="AbtFwOdDvDts">
                                <img src={quoteImg} />
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="AbtFwOdDvDtsClt">
                                    <img src={user1Img} className="mr-2"/>
                                    <div>
                                        <h5>Kattie Williams</h5>
                                        <h6>HCl Pvt, ltd</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="AbtFwOdDvDts">
                                <img src={quoteImg} />
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="AbtFwOdDvDtsClt">
                                    <img src={user2Img} className="mr-2"/>
                                    <div>
                                        <h5>Petter Kensberg</h5>
                                        <h6>Infosys, Fullstack </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="AbtFwOdDvDts">
                                <img src={quoteImg} />
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="AbtFwOdDvDtsClt">
                                    <img src={user1Img} className="mr-2"/>
                                    <div>
                                        <h5>Petter Kensberg</h5>
                                        <h6>Infosys, Fullstack </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </Fragment>
    );
   
}

export default AboutUs;