import { AuthHeader } from "./AuthHeader";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { HanldeApis } from "./utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { userAction } from "../../Redux/actions/userAction";
import * as CryptoJS from "crypto-js";

let key = CryptoJS.enc.Base64.parse("#rGHnfbRDHTEnc1DeckEy#");
let iv = CryptoJS.enc.Base64.parse("#rGHnfVbHRDEnc1DeciV#");

let cancelToken;
export let url = "https://apibafdowj.osizlabs.com/v1";


export const getRequest = async (endpoint, isCancel = false) => {
  await serverTime();
  const authorize = getGen(`${url}/${endpoint}`);
  return makeGetRequest(`${url}/${endpoint}`, "GET", authorize, isCancel);
};

export const putRequest = async (endpoint, body, isCancel = false) => {
  return makeRequest(`${url}/${endpoint}`, "PUT", body, isCancel);
};

export const postRequest = async (endpoint, body, isCancel = false) => {
  console.log(endpoint, "endpoint");
  await serverTime();
  const authorize = getGen(`${url}/${endpoint}`);
  return makeRequest(`${url}/${endpoint}`, "POST", body, authorize, isCancel);
};

export const deleteRequest = async (endpoint, body, isCancel = false) => {
  return makeRequest(`${url}/${endpoint}`, "DELETE", body, isCancel);
};

//Random String
export function randomStringRefferal() {
  let result = "";
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 6) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  console.log(result, "result");
  return result;
}

//Get Server Time
export function serverTime(dispatch) {
  return new Promise((resolve, reject) => {
    fetch(window.location.href, {
      method: "HEAD",
    })
      .then((response) => {
        console.log(response, "response");
        try {
          const dates = new Date(response.headers.get("Date")).getTime();
          const result = Math.round(Number(dates) / 1000);
          localStorage.setItem("serverTime", result + 500);
          console.log(result, "serverDate");
          resolve(result); // Resolve with the server time value
        } catch (e) {
          console.error(e, "Error getting server time");
          reject(e); // Reject with the error if any
        }
      })
      .catch((error) => {
        console.error("Error fetching headers:", error);
        reject(error); // Reject with the error if any
      });
  });
}

export async function getGen(url) {
  var genT = randomStringRefferal();
  console.log(genT, "genT");
  url = url + "/" + genT;
  var date = parseInt(localStorage.getItem("serverTime"), 10);
  var ex_date = date;

  console.log(date, "serverDate1");
  console.log(ex_date, "serverDate11");

  const payload = {
    exp: ex_date, // tokens expiry date in milliseconds
    isHuman: true,
  };
  var token = signToken(payload, url);
  console.log(token, "token");
  var encrtpted_genT = CryptoJS.AES.encrypt(genT, key, {
    iv: iv,
  }).toString();

  console.log(encrtpted_genT, "encrtpted_genT");

  let decipher = CryptoJS.AES.decrypt(encrtpted_genT, key, { iv: iv });
  let decrypt_val = decipher.toString(CryptoJS.enc.Utf8);

  console.log(decrypt_val, "decrypt_val");

  return { token: token, encrtpted_genT: encrtpted_genT };
}

export function base64url(source) {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");
  return encodedSource;
}

export function signToken(payload, key) {
  var secret = key;
  console.log(key, "key");
  let token = encodeToken(payload);
  console.log(token, "token");
  var signature = CryptoJS.HmacSHA256(token, secret);
  signature = base64url(signature);
  console.log(signature, "signature");
  var signedToken = token + "." + signature;
  return signedToken;
}

export function encodeToken(payload) {
  var header = {
    alg: "HS256",
    typ: "JWT",
  };
  var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  var encodedHeader = base64url(stringifiedHeader);
  var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
  var encodedData = base64url(stringifiedData);
  var token = encodedHeader + "." + encodedData;
  return token;
}

const makeGetRequest = async (
  endpoint,
  responseType,
  authorize,
  data,
  isCancel = false
) => {
  if (isCancel) {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
  }
  let jToken = await authorize;
  console.log(jToken, "authorize");
  console.log(endpoint);
  try {
    const resp = await axios({
      url: endpoint,
      method: responseType,
      // data: JSON.stringify(data),
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthHeader().Authorization,
        "access-sign": jToken.token,
        "access-token": jToken.encrtpted_genT,
      },
      cancelToken: isCancel && cancelToken.token,
    });
    return await handleResponse(resp);
  } catch (e) {
    await handleErrors(e.response);
  }
};

const makeRequest = async (
  endpoint,
  responseType,
  data,
  authorize,
  isCancel = false
) => {
  if (isCancel) {
    /**
     * cancel Token
     *  Cancel previous api request
     */
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
  }
  let jToken = await authorize;
  console.log(jToken, "authorize");
  try {
    const resp = await axios({
      url: endpoint,
      method: responseType,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthHeader().Authorization,
        "access-sign": jToken.token,
        "access-token": jToken.encrtpted_genT,
      },
      cancelToken: isCancel && cancelToken.token,
    });
    await handleResponse(resp);

    return resp;
  } catch (e) {
    throw handleErrors(e.response);
  }
};

// const makeRequest = async (endpoint, responseType, data, isCancel = false) => {
//   if (isCancel) {
//     /**
//      * cancel Token
//      *  Cancel previous api request
//      */
//     if (typeof cancelToken != typeof undefined) {
//       cancelToken.cancel("Operation canceled due to new request.");
//     }
//     cancelToken = axios.CancelToken.source();
//   }

//   try {
//     const resp = await axios({
//       url: endpoint,
//       method: responseType,
//       data: data,
//       headers: {
//         Authorization: AuthHeader().Authorization,
//       },
//       cancelToken: isCancel && cancelToken.token,
//     });
//     await handleResponse(resp);

//     return resp.data;
//   } catch (e) {
//     throw handleErrors(e.response);
//   }
// };

const ErrorReload = (res) => {
  if (res != undefined && window.location.pathname !== "/") {
    window.location.replace("/");
  }
  toast.error("You are not authorized to view this page!", {
    className: "toast-msg",
  });
};

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(
    userAction.logout({}, (resp) => {
      if (resp?.status == true) {
        toast.success(resp?.message, {
          className: "toast-msg",
        });
      } else {
        toast.error(resp?.message, {
          className: "toast-msg",
        });
      }
    })
  );
  navigate("/login");
};

const handleErrors = (res) => {
  if (res != undefined)
    if (
      res.data.message === "jwt expired" ||
      res.data.message === "Please authenticate" ||
      res.data.message === "jwt must be provided" ||
      res.data.message ===
      "You session is over, So kindly login again with your credentials!"
    ) {
      toast.error("Session Time Out!", {
        className: "toast-msg",
      });
      Logout();
      localStorage.removeItem("token");
      localStorage.removeItem("tfa");
      localStorage.removeItem("address");
      localStorage.removeItem("_id");
      ErrorReload(res);
    } else if (res.data.code === 500) {
      toast.error("Internal Server Error!", {
        className: "toast-msg",
      });
    } else if (res.status === 401) {
      toast.error(res.data.message, {
        className: "toast-msg",
      });
    }
};

const handleResponse = (response) => {
  if (response.status === 200 || response.status === 201) {
    return response;
  } else if (response.status === 401 || response.message === "jwt expired") {
    throw new Error("UnAuthorised");
  } else if (response.code === 500) {
    toast.error("Session Time Out!", {
      className: "toast-msg",
    });
    Logout();
    if (
      response.message === "jwt expired" ||
      response.message === "Please authenticate"
    ) {
      window.location.href = "/";
      Logout();
      toast.error("Session Time Out!", {
        className: "toast-msg",
      });
    } else {
      toast.error("Internal server error", {
        className: "toast-msg",
      });
    }
  } else {
    toast.error("Network Error", {
      className: "toast-msg",
    });
  }
};
