import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Collapse, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import classnames from 'classnames';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import blogbannerImg from '../../assets/images/blogbanner.png';


const Blog = (props) => {
    
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
    return (
        <Fragment>
            <section className="BlgBnr">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h3>Always hungry for<br/>new knowledge</h3>
                            <p>Reach our blog</p>
                        </div>
                        <div className="col-lg-5">
                            <img src={blogbannerImg} className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="BlgPlrArtclDv">
                <div className="BlgPlrArtclTbBg">
                    <div className="container">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    Students
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Web Development
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Trainee
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    Soft Skills
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '5' })}
                                    onClick={() => { toggle('5'); }}
                                >
                                    Timing
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '6' })}
                                    onClick={() => { toggle('6'); }}
                                >
                                    Branches
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '7' })}
                                    onClick={() => { toggle('7'); }}
                                >
                                    Payments
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '8' })}
                                    onClick={() => { toggle('8'); }}
                                >
                                    Courses
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <TabContent  activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="container">
                            <div className="BlgPlrArtclCnts">
                                <div className="BlgPlrArtclCntsHd">
                                    <h3>Popular articles</h3>
                                    <div className="mb-2">
                                        <a href="#" className="mr-2">See all</a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g opacity="0.8">
                                            <path d="M6 12L10 8L6 4" stroke="#B6BEFF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="BlgPlrArtclCntsHd">
                                    <h3>Development Courses</h3>
                                    <div className="mb-2">
                                        <a href="#" className="mr-2">See all</a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g opacity="0.8">
                                            <path d="M6 12L10 8L6 4" stroke="#B6BEFF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="BlgPlrArtclCntsDt">
                                            <h4>5 ways to become core Java <br/>developer</h4>
                                            <p>The main credit cards Visa and Mastercard are accepted. Can also pay by bank transfer...</p>
                                            <a href="#">Mellissa Suzzano</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
                
            </section>
        </Fragment>
    );
   
}

export default Blog;