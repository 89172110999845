import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
// import angularIcon from "../../assets/images/angularIcon.png";
import { CourseAction } from "../../Redux/actions/CourseAction";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function CourseCard() {
  const [courseMap, setCourseMap] = useState("");
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  useEffect(() => {
    setSkeletonLoader(true);
    dispatch(
      CourseAction.CourseList({}, (resp) => {
        try {
          if (resp.data.status === true) {
            setCourseMap(resp.data.resp);
            setSkeletonLoader(false);
          } else {
            // display a api reponse error
            setSkeletonLoader(false);
          }
        } catch (e) {
          // display a catch error
          setSkeletonLoader(false);
        }
      })
    );
  }, []);

  const CardSkeleton = ({ count }) => {
    let jsx = [];
    for (let i = 0; i < count; i++) {
      jsx.push(
        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
          <div className="col-sm-6 col-lg-4 col-xl-3 mt-3">
            <div className="courseCnt">
              <div className="bannerCnt d-flex align-items-center flex-column">
                <span>
                  <Skeleton width="100px" height="100px" />
                </span>
              </div>
              <span>
                <Skeleton width="220px" height="20px" />
              </span>
              <a className="btn darkBtn">
                <Skeleton width="80%" height="50%" />
              </a>
            </div>
          </div>
        </SkeletonTheme>
      );
    }
    return <>{jsx}</>
  };

  return (
    <>
      {skeletonLoader ? (
        <CardSkeleton count={4} />
      ) : (
        <>
          {courseMap && courseMap.length > 0 ? (
            courseMap.map((d) => (
              d.coursedetails ?
              <div className="col-sm-6 col-lg-4 col-xl-3 mt-3">
                <div className="courseCnt">
                  <div className="bannerCnt d-flex align-items-center flex-column">
                    <img src={d.image} className="img-fluid" />
                    <h3 className="heading">{d.coursetitle}</h3>
                  </div>
                  <h3 className="infoHeading">{d.coursetitle} </h3>
                  <a
                    onClick={() => navigate(`/coursedetails/${d._id}`)}
                    className="btn darkBtn"
                  >
                    Learn More
                  </a>
                </div>
              </div> : null
            ))
          ) : (
            <div className="container">
              <div className="row justify-content-center">
                <p className="secSubHeading">No Courses Found !</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
