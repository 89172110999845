import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/style.scss';
import {
   BrowserRouter, Route, Routes
} from 'react-router-dom';
import Home from './views/Home/Home';
import Terms from './views/Terms/Terms';
import Privacy from './views/Privacy/Privacy';
import Contact from './views/Contact/Contact';
import CourseDetails from './views/CourseDetails/CourseDetails';
import AboutUs from './views/AboutUs/AboutUs'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Blog from './views/Blog/Blog';
import BlogDetails from './views/BlogDetails/BlogDetails';
import FAQ from './views/FAQ/FAQ';

const App = (props) => {
   return (
      <React.Fragment>
         <BrowserRouter>
            <Header />
            <Routes history={props.history}>
              <Route path='/' element={<Home />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/coursedetails/:_id' element={<CourseDetails />} />
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/blogdetails' element={<BlogDetails />} />
              <Route path='/faq' element={<FAQ />} />
            </Routes>
            <Footer />
         </BrowserRouter>
      </React.Fragment>
   );
}

export default App;