import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerImg from '../../assets/images/bannerImg.png';
import realTimeIcon from '../../assets/images/realTimeIcon.gif';
import liveprojectIcon from '../../assets/images/liveprojectIcon.gif';
import reliableIcon from '../../assets/images/reliableIcon.gif';
import flexibilityIcon from '../../assets/images/flexibilityIcon.gif';
import placementIcon from '../../assets/images/placementIcon.gif';
import feesIcon from '../../assets/images/feesIcon.gif';
import formImg from '../../assets/images/formImg.png';
import angularIcon from '../../assets/images/angularIcon.png';
import reactIcon from '../../assets/images/reactIcon.png';
import javaIcon from '../../assets/images/javaIcon.png';
import phpIcon from '../../assets/images/phpIcon.png';
import htmlIcon from '../../assets/images/htmlIcon.png';
import peopleIcon from '../../assets/images/peopleIcon.svg';
import mapPointer from '../../assets/images/mapPointer.png';
import arrowIcon from '../../assets/images/arrowIcon.svg';
import successUser1 from '../../assets/images/successUser1.png';
import successUser2 from '../../assets/images/successUser2.png';
import serviceBgBefore from '../../assets/images/serviceBgBefore.png';
import trendingBgBefore from '../../assets/images/trendingBgBefore.png';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Enquiry from "../../components/Enquiry/Enquiry";
// import Loader from "../loader/Loader";
import Chennai from "../../assets/maps/Chennai";
import Trichy from "../../assets/maps/Trichy";
import Coimbatore from "../../assets/maps/Coimbatore";
import Tirunelveli from "../../assets/maps/Tirunelveli";
import Maps from "../../components/Maps/Maps";
import CourseCard from "./CourseCard";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import Helmets from "../../components/helmet/Helmets";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Home = (props) => {
    const dispatch = useDispatch();
    const [homeBanners, setHomeBanners] = useState('')
    const [skeletonLoader, setSkeletonLoader] = useState(false);

    // const [realText, setRealText] = useState("");
    // const [liveProject,setLiveProjectText] = useState('')
    // const [reliable,setRealiableText] = useState('')
    // const [affortable,setAffortabletext] = useState('')
    // const [students,setStudentsText] = useState('')
    // const [placement,setPlacementText] = useState('')
    // const [osizacadamy,setOsizAcademy] = useState('')
    // const [whyOsiz,setWhyOsizText] = useState('')
    // const [mapContent,setMapContent] = useState('')
    // const [internshipText, setInternShipText] = useState('');
    // const [itCourseText, setItCourseText] = useState('');

    useEffect(() => {
        setSkeletonLoader(true);
        dispatch(userAction.ListCms({}, (resp) => {
            try{
                if(resp.data.status === true){
                    setHomeBanners(resp.data.resp)
                    setSkeletonLoader(false);
                }
                else{
                    setSkeletonLoader(false);
                    // display a api reponse error
                }
            }
            catch(e){
                setSkeletonLoader(false);
                // display a catch error
            }
        }))
    }, []);

    let realText = homeBanners && homeBanners.find(d => d._id === "65705a5dc7d25841de96cef8")
    let liveProject = homeBanners && homeBanners.find(d => d._id === "65705af9c7d25841de96cf1b")
    let reliable = homeBanners && homeBanners.find(d => d._id === "65705b0fc7d25841de96cf22")
    let affortable = homeBanners && homeBanners.find(d => d._id === "65705b23c7d25841de96cf29")
    let students = homeBanners && homeBanners.find(d => d._id === "65705b3ac7d25841de96cf30")
    let placement = homeBanners && homeBanners.find(d => d._id === "65705b4fc7d25841de96cf37")
    let osizacadamy = homeBanners && homeBanners.find(d => d._id === "65705b9bc7d25841de96cf4e")
    let whyOsiz = homeBanners && homeBanners.find(d => d._id === "65714ca6a9eafb5053b19b29")
    let mapContent = homeBanners && homeBanners.find(d => d._id === "6572c0d8ef7b79095ac763f5")
    let internshipText = homeBanners && homeBanners.find(d => d._id === "6572f76b3c3bab2af0910693")
    let itCourseText = homeBanners && homeBanners.find(d => d._id === "65731988b735a83a8af156d5")


    const getCMSListDatas = () => {
        // dispatch(userAction.GetSingleCms({
        //     id: "65705a5dc7d25841de96cef8",
        // }, (resp) => {
        //     setRealText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705af9c7d25841de96cf1b",
        // }, (resp) => {
        //     setLiveProjectText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705b0fc7d25841de96cf22",
        // }, (resp) => {
        //     setRealiableText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705b23c7d25841de96cf29",
        // }, (resp) => {
        //     setAffortabletext(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705b3ac7d25841de96cf30",
        // }, (resp) => {
        //     setStudentsText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705b4fc7d25841de96cf37",
        // }, (resp) => {
        //     setPlacementText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65705b9bc7d25841de96cf4e",
        // }, (resp) => {
        //     setOsizAcademy(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65714ca6a9eafb5053b19b29",
        // }, (resp) => {
        //     setWhyOsizText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "6572c0d8ef7b79095ac763f5",
        // }, (resp) => {
        //     setMapContent(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "6572f76b3c3bab2af0910693",
        // }, (resp) => {
        //     setInternShipText(resp.data.resp)
        // }))
        // dispatch(userAction.GetSingleCms({
        //     id: "65731988b735a83a8af156d5",
        // }, (resp) => {
        //     setItCourseText(resp.data.resp)
        // }))
    };

    const Star = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                    <g clip-path="url(#clip0_72_1815)">
                        <path d="M4.34965 3.36413L1.42548 3.78809L1.37369 3.79863C1.29529 3.81944 1.22382 3.86069 1.16657 3.91817C1.10932 3.97564 1.06835 4.04727 1.04785 4.12576C1.02734 4.20424 1.02803 4.28676 1.04985 4.36489C1.07166 4.44302 1.11382 4.51396 1.17203 4.57046L3.29044 6.63251L2.79086 9.54521L2.7849 9.59563C2.7801 9.67672 2.79694 9.75763 2.83369 9.83007C2.87044 9.90251 2.92579 9.96389 2.99405 10.0079C3.06232 10.0519 3.14107 10.077 3.22222 10.0806C3.30337 10.0842 3.38402 10.0661 3.4559 10.0283L6.07115 8.6533L8.68044 10.0283L8.72628 10.0494C8.80193 10.0792 8.88415 10.0883 8.9645 10.0759C9.04485 10.0634 9.12043 10.0298 9.18351 9.97846C9.24658 9.92715 9.29486 9.85997 9.32341 9.78384C9.35195 9.7077 9.35973 9.62535 9.34594 9.54521L8.8459 6.63251L10.9652 4.57001L11.001 4.53105C11.0521 4.46815 11.0855 4.39284 11.098 4.31279C11.1105 4.23274 11.1016 4.1508 11.0721 4.07534C11.0426 3.99988 10.9936 3.93358 10.9302 3.8832C10.8667 3.83282 10.791 3.80016 10.7109 3.78855L7.78669 3.36413L6.47953 0.714965C6.4417 0.638209 6.38315 0.573576 6.31049 0.528379C6.23783 0.483183 6.15397 0.459229 6.0684 0.459229C5.98283 0.459229 5.89897 0.483183 5.82631 0.528379C5.75366 0.573576 5.6951 0.638209 5.65728 0.714965L4.34965 3.36413Z" fill="#FFBA2E" />
                    </g>
                    <defs>
                        <clipPath id="clip0_72_1815">
                            <rect width="11" height="11" fill="white" transform="translate(0.571533)" />
                        </clipPath>
                    </defs>
                </svg>
            </>
        )
    }
    const StarUnfill = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_72_1694)">
                        <path d="M4.8084 4.28162L1.08674 4.82121L1.02082 4.83462C0.921033 4.86111 0.830066 4.91361 0.757206 4.98676C0.684346 5.0599 0.632204 5.15107 0.606105 5.25096C0.580006 5.35085 0.580885 5.45588 0.608652 5.55531C0.636418 5.65475 0.690079 5.74504 0.764152 5.81695L3.46032 8.44137L2.82449 12.1485L2.8169 12.2126C2.81079 12.3158 2.83222 12.4188 2.879 12.511C2.92577 12.6032 2.99621 12.6813 3.0831 12.7373C3.16999 12.7934 3.2702 12.8253 3.37349 12.8299C3.47677 12.8344 3.57941 12.8114 3.6709 12.7633L6.9994 11.0133L10.3203 12.7633L10.3787 12.7901C10.4749 12.828 10.5796 12.8397 10.6818 12.8238C10.7841 12.808 10.8803 12.7652 10.9606 12.6999C11.0409 12.6345 11.1023 12.5491 11.1386 12.4522C11.175 12.3553 11.1849 12.2504 11.1673 12.1485L10.5309 8.44137L13.2282 5.81637L13.2737 5.76679C13.3387 5.68674 13.3813 5.59089 13.3972 5.489C13.4131 5.38712 13.4017 5.28284 13.3642 5.1868C13.3267 5.09075 13.2644 5.00637 13.1836 4.94225C13.1028 4.87813 13.0065 4.83657 12.9045 4.82179L9.18282 4.28162L7.51915 0.909955C7.47101 0.812267 7.39649 0.730005 7.30401 0.672482C7.21154 0.61496 7.10481 0.584473 6.9959 0.584473C6.887 0.584473 6.78027 0.61496 6.68779 0.672482C6.59532 0.730005 6.52079 0.812267 6.47265 0.909955L4.8084 4.28162Z" fill="white" fill-opacity="0.47" />
                    </g>
                    <defs>
                        <clipPath id="clip0_72_1694">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </>
        )
    }

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const settings1 = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };

    const CardSkeleton = ({ count }) => {
        let jsx = [];
        for (let i = 0; i < count; i++) {
          jsx.push(
            <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                   <div className="col-md-6 col-lg-4 col-xl-4 mb-2">
                            <div className="serviceBoxCnt purple">
                                <div className="imgCnt">
                                <Skeleton width="100px" height="100px" />
                                </div>
                                <br/>
                                <h3><Skeleton width="80%" height="50%" /></h3>
                                <p> <Skeleton width="80%" height="50%" /></p>
                            </div>
                        </div>
            </SkeletonTheme>
          );
        }
        return <>{jsx}</>
      };

    return (
        <Fragment>
            < Helmets title={"Osiz Labs"} description ={"Osiz Labs Home Page"} />
            <section className="bannerSec">
                
                  <HomeSlider/>
                
            </section>

            <section className="serviceSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <h3 className="secHeading">Compelling <br />Features</h3>
                            <img src={serviceBgBefore} className="headingImg" />
                        </div>
                    </div>
                </div>
           
                <div className="container">
                    <div className="row">
                    {skeletonLoader ? (
        <CardSkeleton count={6} />
      ) : (<>
                        <div className="col-md-6 col-lg-4 col-xl-4 mb-2">
                            <div className="serviceBoxCnt purple">
                                <div className="imgCnt">
                                    <img src={realTimeIcon} className="icon" />
                                </div>
                                <h3>{realText && realText.title || 'No Data Found'}</h3>
                                <p dangerouslySetInnerHTML={{__html: realText.content  || 'No Data Found'}}></p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2">
                            <div className="serviceBoxCnt red">
                                <div className="imgCnt">
                                    <img src={liveprojectIcon} className="icon" />
                                </div>

                                <h3>{liveProject && liveProject.title || 'No Data Found'}</h3>

                                <p dangerouslySetInnerHTML={{__html: liveProject.content  || 'No Data Found'}}></p>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2">
                            <div className="serviceBoxCnt cyan">
                                <div className="imgCnt">
                                    <img src={reliableIcon} className="icon" />
                                </div>

                                <h3>{reliable && reliable.title || 'No Data Found'}</h3>

                                <p dangerouslySetInnerHTML={{__html: reliable.content  || 'No Data Found'}}></p>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2">
                            <div className="serviceBoxCnt yellow">
                                <div className="imgCnt">
                                    <img src={feesIcon} className="icon" />
                                </div>

                                <h3>{affortable && affortable.title || 'No Data Found'}</h3>

                                <p dangerouslySetInnerHTML={{__html: affortable.content  || 'No Data Found'}}></p>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2">
                            <div className="serviceBoxCnt blue">
                                <div className="imgCnt">
                                    <img src={flexibilityIcon} className="icon" />
                                </div>

                                <h3>{students && students.title || 'No Data Found'}</h3>

                                <p dangerouslySetInnerHTML={{__html: students.content  || 'No Data Found'}}></p>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2 ">
                            <div className="serviceBoxCnt orange">
                                <div className="imgCnt">
                                    <img src={placementIcon} className="icon" />
                                </div>

                                <h3>{placement && placement.title || 'No Data Found'}</h3>

                                <p dangerouslySetInnerHTML={{__html: placement.content  || 'No Data Found'}}></p>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-4  mb-2">
                            <div className="serviceBoxCnt purple">
                                <div className="imgCnt">
                                    <img src={realTimeIcon} className="icon" />
                                </div>
                                <h3>{internshipText && internshipText.title || 'No Data Found'}</h3>
                                <p dangerouslySetInnerHTML={{__html: internshipText.content  || 'No Data Found'}}></p>
                            </div>
                        </div>
                        </>)}
                    </div>
                </div>
            </section>

            <section className="labFormSec">
                <div className="container labFormContainer">
                    <div className="labFormCnt">
                        <div className="container">
                            <div className="row no-gutters">
                            <div className="col-12 ">
                                    <h3 className="heading">{osizacadamy && osizacadamy.title || 'No Data Found'}</h3>
                                    <div dangerouslySetInnerHTML={{__html: osizacadamy.content  || 'No Data Found'}}></div>
                                </div>
                                
                                <div className="col-md-12 col-xl-4">
                                   
                                    <h3 className="heading">{whyOsiz && whyOsiz.title || 'No Data Found'}</h3>

                                    <ul>
                                        <div dangerouslySetInnerHTML={{__html: whyOsiz.content || 'No Data Found'}}></div>
                                    </ul>
                                </div>

                                <div className="col-lg-4 col-xl-4 d-flex justify-content-center align-items-end">
                                    <img src={formImg} className="img-fluid bannerImg" />
                                </div>

                                <div className="col-lg-8 col-xl-4">
                                    <div className="formCnt">
                                        <h3 className="heading">Quick Enquiry</h3>
                                      <Enquiry/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mapSec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <h3 className="secHeading text-center">{mapContent && mapContent.title || 'No Data Found'}</h3>
                            <p className="secSubHeading" dangerouslySetInnerHTML={{__html: mapContent.content  || 'No Data Found'}}></p>
                        </div>
                        <div className="col-lg-9">
                            <Maps/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="trendingSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="secHeading">Trending<br />IT Courses</h3>
                            <div className="mt-4"> <p dangerouslySetInnerHTML={{__html: itCourseText.content  || 'No Data Found'}}></p></div>
                            <img src={trendingBgBefore} className="headingImg" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <CourseCard />
                        {/* <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="courseCnt">
                                <div className="bannerCnt d-flex align-items-center flex-column">
                                    <img src={reactIcon} />
                                    <h3 className="heading">React JS</h3>
                                    <div className="d-flex justify-content-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <StarUnfill />
                                    </div>
                                </div>
                                <h3 className="infoHeading">React JS <span>(Web development)</span></h3>
                                <h3 className="infoHeading"><img src={peopleIcon} className="mr-2" />500+</h3>
                                <a href="" className="btn darkBtn">Learn More</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="courseCnt">
                                <div className="bannerCnt d-flex align-items-center flex-column">
                                    <img src={javaIcon} />
                                    <h3 className="heading">Java</h3>
                                    <div className="d-flex justify-content-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <StarUnfill />
                                    </div>
                                </div>
                                <h3 className="infoHeading">Java <span>(Web development)</span></h3>
                                <h3 className="infoHeading"><img src={peopleIcon} className="mr-2" />500+</h3>
                                <a href="" className="btn darkBtn">Learn More</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="courseCnt">
                                <div className="bannerCnt d-flex align-items-center flex-column">
                                    <img src={phpIcon} />
                                    <h3 className="heading">PHP</h3>
                                    <div className="d-flex justify-content-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <StarUnfill />
                                    </div>
                                </div>
                                <h3 className="infoHeading">PHP <span>(Web development)</span></h3>
                                <h3 className="infoHeading"><img src={peopleIcon} className="mr-2" />500+</h3>
                                <a href="" className="btn darkBtn">Learn More</a>
                            </div>
                        </div> */}
                        {/* <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="courseCnt">
                                <div className="bannerCnt d-flex align-items-center flex-column">
                                    <img src={htmlIcon} />
                                    <h3 className="heading">HTML 5</h3>
                                    <div className="d-flex justify-content-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <StarUnfill />
                                    </div>
                                </div>
                                <h3 className="infoHeading">HTML 5 <span>(Web development)</span></h3>
                                <h3 className="infoHeading"><img src={peopleIcon} className="mr-2" />500+</h3>
                                <a href="" className="btn darkBtn">Learn More</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <div className="courseCnt">
                                <div className="bannerCnt d-flex align-items-center flex-column">
                                    <img src={angularIcon} />
                                    <h3 className="heading">Angular JS</h3>
                                    <div className="d-flex justify-content-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <StarUnfill />
                                    </div>
                                </div>
                                <h3 className="infoHeading">Angular JS <span>(Web development)</span></h3>
                                <h3 className="infoHeading"><img src={peopleIcon} className="mr-2" />500+</h3>
                                <a href="" className="btn darkBtn">Learn More</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
{/* 
            <section className="successSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <h3 className="secHeading">Our Success<br />Stories</h3>
                            <img src={serviceBgBefore} className="headingImg" />
                            <h5 className="secSubHeading text-left">Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</h5>
                            <div className="d-flex align-items-center">
                                <h3 className="percVal d-flex align-items-center"><img src={arrowIcon} className="mr-2" />97%</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. At urna  eget auctor. Sed tincidunt nulla nibh.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-center justify-content-lg-end successSliderContainer">
                    <div className="successSliderCnt">
                        <Slider {...settings1}>
                            <div className="successCardCnt" style={{ width: 368 }}>
                                <div className="starCnt d-flex mb-3">
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                                <h3 className="heading">Comprehensive</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="userInfoCnt d-flex align-items-center">
                                    <img src={successUser1} className="userIcon" />
                                    <div className="d-flex flex-column">
                                        <h3 className="userName">Kattie Williams</h3>
                                        <p>HCl Pvt, ltd</p>
                                    </div>
                                </div>
                            </div>
                            <div className="successCardCnt" style={{ width: 368 }}>
                                <div className="starCnt d-flex mb-3">
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                                <h3 className="heading">Comprehensive</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="userInfoCnt d-flex align-items-center">
                                    <img src={successUser1} className="userIcon" />
                                    <div className="d-flex flex-column">
                                        <h3 className="userName">Kattie Williams</h3>
                                        <p>HCl Pvt, ltd</p>
                                    </div>
                                </div>
                            </div>
                            <div className="successCardCnt">
                                <div className="starCnt d-flex mb-3">
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                                <h3 className="heading">Comprehensive</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. At feugiat nunc urna amet eget auctor. Sed tincidunt nulla nibh.</p>
                                <div className="userInfoCnt d-flex align-items-center">
                                    <img src={successUser1} className="userIcon" />
                                    <div className="d-flex flex-column">
                                        <h3 className="userName">Kattie Williams</h3>
                                        <p>HCl Pvt, ltd</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section> */}
        </Fragment>
    );

}

export default Home;