
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}



export function receiveLogout() {
  
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem('authenticated');
    localStorage.clear();
    dispatch(receiveLogout() );
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    dispatch(receiveLogin());
    if (creds.email.length > 0 && creds.password.length > 0) {
      toast.success("🤗 You've been Login successfully");  
      localStorage.setItem('authenticated', true)
    } else {
      dispatch(loginError('Something was wrong. Try again'));
      toast.error("Something was wrong. Try again");  
      
    }
  }
}

