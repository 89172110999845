import * as apiHelper from '../../views/helper/ApiHelper'

export const userService = {
  ListCms,
  GetSingleCms,
  GetSiteSettings,
  GetCourseList,
  AddContact,
};


async function ListCms(data) {
  try {
    const api = await apiHelper.getRequest(`user/cmsContentGet`, data);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}
async function GetSingleCms({id}) {
  try {
    const api = await apiHelper.postRequest(`user/cmsContentGetId?id=${id}`);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}
async function GetSiteSettings(data) {
  try {
    const api = await apiHelper.getRequest(`user/site-setting`,data);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}
async function GetCourseList(data) {
  try {
    const api = await apiHelper.getRequest(`user/getcourse`,data);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}
async function AddContact(data) {
  try {
    const api = await apiHelper.postRequest(`contactus/addcontactus`,data);
    return api;
  } catch (e) {
    // API Response Catch Error
    return e.toString();
  }
}