import React, { Fragment, useState, useEffect  } from "react";
import termsBanner from '../../assets/images/termsBanner.png';
import { userAction } from '../../Redux/actions/userAction'
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Aos from "aos";
import Helmets from "../../components/helmet/Helmets";

const Terms = (props) => {   
    const dispatch = useDispatch();
    const [getData, setData] = useState('')
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setLoader(true)
       dispatch(userAction.GetSingleCms({
        id: "656f1af1d28252f86ff9d791",
       }, (resp) => {
        try{
            if(resp.data.status === true){
                setData(resp.data.resp.content)
                setLoader(false)
            }
            else{
                setLoader(false)
                // display a api reponse error
            }
        }
        catch(e){
            setLoader(false)
            // display a catch error
        }
       }))

       Aos.init({ once: true });

       window.scrollTo(0, 0);

    }, [])

    return (
        <Fragment>
            < Helmets title={"Terms and Conditions"} description ={"Osiz Labs Terms and Conditions Page"} />
            <section className="cmsSecCnt">
                <div className="cmsBanner d-flex justify-content-center align-items-center">
                    <img src={termsBanner} className="cmsBannerImg" />
                    <div className="container">
                        <h3 className="cmsHeading">Terms and Conditions</h3>
                    </div>
                </div>
                <div className="cmsTextCnt">
                    <div className="container">
                    {loader ?
                        <SkeletonTheme baseColor="#07070f" highlightColor="#444">
                            <Skeleton width="100%" height="100%"  />
                            <Skeleton width="80%" height="50%"  />
                         </SkeletonTheme> :
                         <div data-aos="zoom-out-up" data-aos-duration="1000">
                    <p dangerouslySetInnerHTML={{__html: getData  || 'No Data Found'}}></p></div> }
                    </div>
                </div>
            </section>
        </Fragment>
    );
   
}

export default Terms;